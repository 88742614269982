import { Injectable, inject } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import {
  Action,
  Selector,
  State,
  StateContext,
  createSelector,
} from '@ngxs/store';
import { Session as KratosSession } from '@ory/client';
import { catchError, from, of, tap } from 'rxjs';
import { OrganizationMembership, User } from 'src/app/models/user';
import { KratosAuthenticationService } from 'src/app/services/authentication/kratos.service';
import { IntercomService } from 'src/app/services/intercom/intercom.service';
import { LoadOrganizationAction } from '../organization/organization.actions';
import {
  LoadUserAction,
  LogoutAction,
  SelectOrganizationAction,
  SetSessionAction,
} from './user.actions';
import { UserService } from './user.service';

export interface Session {
  id: string;
  authenticatedAt: Date;
  issuedAt: Date;
  expiresAt: Date;
  assuranceLevel: string;
  fullSession: KratosSession;
}

export interface UserStateModel {
  user?: User;
  session?: Session;
  selectedOrganizationId?: string;
}

@State<UserStateModel>({
  name: 'user',
  defaults: {
    user: undefined,
    session: undefined,
    selectedOrganizationId: undefined,
  },
})
@Injectable()
export class UserState {
  private kratos = inject(KratosAuthenticationService);
  private userService = inject(UserService);
  private intercom = inject(IntercomService);

  @Selector()
  static getCurrentOrganizationID(state: UserStateModel) {
    return state.selectedOrganizationId;
  }

  @Selector()
  static membership(state: UserStateModel): OrganizationMembership | undefined {
    const membership = state.user?.memberships.find(membership => {
      return membership.organizationId === state.selectedOrganizationId;
    });

    return membership;
  }

  @Selector()
  static getUser(state: UserStateModel) {
    return state.user;
  }

  @Selector()
  static getSession(state: UserStateModel) {
    return state.session;
  }

  @Selector([UserState.membership])
  static getPermissionRoles(
    state: UserStateModel,
    membership: OrganizationMembership
  ) {
    return membership.role.permissionRoles;
  }

  static checkPermissionRole(roles: string[]) {
    return createSelector(
      [UserState.membership],
      (membership: OrganizationMembership) => {
        for (const role of roles) {
          if (membership.role.permissionRoles.includes(role)) return true;
        }

        return false;
      }
    );
  }

  @Action(LoadUserAction)
  loadUser(ctx: StateContext<UserStateModel>) {
    // fetch user
    return this.userService.loadUser().pipe(
      tap(user => {
        ctx.patchState({
          user: user,
        });

        const state = ctx.getState();

        if (user.memberships.length > 0) {
          if (
            // user has no organization selected
            // user is not a member of the currently selected organization
            !user.memberships.find(
              membership =>
                membership.organizationId === state.selectedOrganizationId
            )
          ) {
            ctx.dispatch(
              new SelectOrganizationAction(user.memberships[0].organizationId)
            );
          } else {
            ctx.dispatch(new LoadOrganizationAction());
          }
        }
      }),
      catchError(err => {
        if (err.message.includes('unauthenticated')) {
          console.warn('User is not authenticated.. logging out');
          ctx.dispatch(new LogoutAction());
          return of([]);
        }

        throw err;
      })
    );
  }

  @Action(SelectOrganizationAction)
  setOrganizationId(
    ctx: StateContext<UserStateModel>,
    { organizationId }: SelectOrganizationAction
  ) {
    let refetchUser = false;

    // if the organization id has changed refetch the user to get permission
    // for this organization
    if (ctx.getState().selectedOrganizationId != organizationId) {
      refetchUser = true;
    }

    ctx.patchState({
      selectedOrganizationId: organizationId,
    });

    if (refetchUser) {
      ctx.dispatch(new LoadUserAction());
    }
  }

  @Action(SetSessionAction)
  setSession(ctx: StateContext<UserStateModel>, { session }: SetSessionAction) {
    ctx.patchState({
      session: session,
    });
  }

  @Action(LogoutAction)
  logout(
    ctx: StateContext<UserStateModel>,
    { redirectUrl, queryParams }: LogoutAction
  ) {
    return from(this.kratos.logout()).pipe(
      tap(() => {
        // clear state
        ctx.setState({
          session: undefined,
          user: undefined,
          selectedOrganizationId: undefined,
        });

        ctx.dispatch(
          new Navigate([redirectUrl ? redirectUrl : '/login'], queryParams)
        );
      })
    );
  }
}
