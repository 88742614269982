import { inject, Injectable } from '@angular/core';
import Intercom from '@intercom/messenger-js-sdk';
import { InitType } from '@intercom/messenger-js-sdk/dist/types';
import { createModelSelector, Store } from '@ngxs/store';
import { map } from 'rxjs';
import { Organization } from 'src/app/models/organization';
import { User } from 'src/app/models/user';
import { OrganizationDetailsState } from 'src/app/store/organization/organization.state';
import { UserState } from 'src/app/store/user/user.state';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  private store = inject(Store);

  constructor() {
    this.store
      .select(
        createModelSelector({
          user: UserState.getUser,
          selectedOrganization: OrganizationDetailsState.organization,
        })
      )
      .pipe(
        map(({ user, selectedOrganization }) => {
          if (!user || !selectedOrganization.data) return;

          this.initialize(user, selectedOrganization.data);
        })
      )
      .subscribe();
  }

  public initialize(user: User, organization: Organization) {
    if (environment.intercom.enabled) {
      // create companies
      const intercomInit: InitType = {
        app_id: environment.intercom.appId,
        company: {
          company_id: organization.id,
          name: organization.name,
          plan: organization.subscription.plan.name,
        },
        user_id: user.id,
        user_hash: user.icUserHash,
        name: `${user.firstname} ${user.lastname}`,
        email: user.email,
        avatar: {
          type: 'avatar',
          image_url: user.avatar,
        },
        created_at: user.createdAt.getUTCSeconds(),
        action_color: '#117cfa',
        background_color: '#1f2d40',
      };

      Intercom(intercomInit);
    }
  }
}
