import { HttpClient, HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { NgxsModule } from '@ngxs/store';
import * as Sentry from '@sentry/angular-ivy';
import { INTL_LOCALES, IntlModule } from 'angular-ecmascript-intl';
import { CookieService } from 'ngx-cookie-service';
import superjson from 'superjson';
import { AppComponent } from './app.component';
import { GraphQLModule } from './graphql.module';
import { PagesModule } from './pages/pages.module';
import { KratosAuthenticationService } from './services/authentication/kratos.service';

import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

import { registerLocaleData } from '@angular/common';
import {
  TranslateCompiler,
  TranslateDefaultParser,
  TranslateLoader,
  TranslateModule,
  TranslateParser,
} from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { createTranslateLoader } from './services/translation/http-loader';
import { MessageParser } from './services/translation/parser';

import localeDE from '@angular/common/locales/de';
import localeEN from '@angular/common/locales/en';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import {
  LOCAL_STORAGE_ENGINE,
  NgxsStoragePluginModule,
} from '@ngxs/storage-plugin';
import { NgxStripeModule } from 'ngx-stripe';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ServicesService } from './pages/internal/services/services.service';
import { ServicesState } from './pages/internal/services/services.state';
import { IntercomService } from './services/intercom/intercom.service';
import { ObservabilityService } from './services/observability/observability.service';
import { PermissionModule } from './services/permission/permission.module';
import { ToastService } from './services/toast/toast.service';
import { FeaturesStateModule } from './store/features/features.state.module';
import { MiscState } from './store/misc/misc.state';
import { UserState } from './store/user/user.state';
import { UserStateModule } from './store/user/user.state.module';

registerLocaleData(localeDE);
registerLocaleData(localeEN);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    PagesModule,
    GraphQLModule,
    IntlModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
      parser: {
        provide: TranslateParser,
        useClass: MessageParser,
        deps: [TranslateDefaultParser],
      },
    }),
    UserStateModule,
    FeaturesStateModule,
    NgxsModule.forRoot([ServicesState, MiscState]),
    NgxsRouterPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      key: [
        {
          key: UserState,
          engine: LOCAL_STORAGE_ENGINE,
        },
      ],
      serialize: obj => {
        return superjson.stringify(obj);
      },
      deserialize: obj => {
        return superjson.parse(obj);
      },
    }),
    NgxsFormPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({
      latency: 0,
    }),
    ToastModule,
    PermissionModule,
    FontAwesomeModule,
    NgxStripeModule.forRoot(),
  ],
  providers: [
    KratosAuthenticationService,
    ServicesService,
    TranslateDefaultParser,
    ToastService,
    MessageService,
    CookieService,
    ObservabilityService,
    IntercomService,
    // Sentry error tracking
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'en-US',
    },
    {
      provide: INTL_LOCALES,
      useValue: ['en-US'],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
